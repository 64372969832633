import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./TestimonialSlider.css";
import hobertLow1 from "../Assets/Assets/Hobart-Low-1.jpg";
import hobertLow2 from "../Assets/Assets/Hobart-Low-2.jpg";
import thomasSim1 from "../Assets/Assets/thomas-sim.jpg";
import thomasSim2 from "../Assets/Assets/thomas-sim-2.jpg";
import richy1 from "../Assets/Assets/Richy-1.jpg";
import richy2 from "../Assets/Assets/Richy-2.jpg";
import younus1 from "../Assets/Assets/Younus-Moosani-1.jpg";
import younus2 from "../Assets/Assets/Younus-Moosani-2.jpg";
import flores1 from "../Assets/Assets/Moises-Solis-1.jpg";
import flores2 from "../Assets/Assets/Moises-Solis-2.jpg";
import soliman1 from "../Assets/Assets/Ahmed-Soliman-1.jpg";
import soliman2 from "../Assets/Assets/Ahmed-Soliman-2.jpg";
import diego1 from "../Assets/Assets/DIEGO PUTZOLU 1 .webp";
import diego2 from "../Assets/Assets/DIEGO PUTZOLU 2 .webp";
import governor from "../Assets/Assets/governor.jpg";
import presidentImage from "../Assets/Assets/Ramadas-Naidu-2.jpg";
import presidentImageBio from "../Assets/Assets/Ramadas-Naidu-1.jpg";
import Stevan1 from "../Assets/Assets/Steven Xue 1.jpg";
import Stevan2 from "../Assets/Assets/Steven Xue 2.jpg";
import Accordion from "./Accordation";
import { useTranslation } from "react-i18next";
import BoardOfGovernorsMobile from "./BoardOfGovernorsMobile";
import governor2 from "../Assets/Assets/0001.jpg";

const CustomSlider = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const sliderRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(null);

  const slides = [
    {
      name: "Ramadas Naidu",
      topTitle: "President",
      title: "President",
      description: `${t("testimonial.Ramadas_description")}`,
      image: presidentImage,
      image1: presidentImageBio,
      isPresident: true,
      linkedin: "https://www.linkedin.com/in/s-k-ramadas-naidu-95b543a/", // Ramadas Naidu's LinkedIn URL
    },

    {
      name: "Hobart Low",
      topTitle: "Global Chairman",
      title: "Global",
      description: `${t("testimonial.Hobart_description")}`,
      image: hobertLow2,
      image1: hobertLow1,
      isChairman: true,
      linkedin: "https://www.linkedin.com/in/hobart-low-5b232a43",
    },
    {
      name: "Thomas SIM",
      title: "Chief Advisor",
      topTitle: "Chief Advisor",
      description: `${t("testimonial.Thomas_description")}`,
      image: thomasSim2,
      image1: thomasSim1,
      linkedin: "https://www.linkedin.com/in/thomassimsg/",
    },
    {
      name: "Roland Quah",
      topTitle: "Governor",
      title: "Governor",
      description: `${t("testimonial.Roland_description")}`,
      image: governor,
      image1: governor,
      linkedin: "https://www.linkedin.com/in/roland-quah-4b5893a1/",
    },
    {
      name: "Josh Fairbank",
      topTitle: "Governor",
      title: "Governor",
      description: `${t("testimonial.Josh_description")}`,
      image: governor2,
      image1: governor2,
    },
    {
      name: "Richard Wade",
      title: "North America",
      topTitle: "North America",
      description: `${t("testimonial.Richard_description")}`,
      image: richy2,
      image1: richy1,
      linkedin: " https://www.linkedin.com/in/richard-wade-8b5a54188/",
    },

    {
      name: "Younus Moosani",
      title: "South Asia",
      topTitle: "South Asia",
      description: `${t("testimonial.Younus_description")}`,
      image: younus1,
      image1: younus2,
      linkedin: " https://www.linkedin.com/in/younusmoosani/",
    },
    {
      name: "Steven Xue",
      topTitle: "Asia-Pacific",
      title: "Asia-Pacific",
      //description: `Steven Xue is a seasoned professional with over 22 years of expertise in international freight forwarding, cross-border logistics, and supply chain management. He has successfully established and managed various companies, including Qingdao COSCO Overseas Business Department and Qingdao Greatmicro Supply Chain Co., Ltd. Steven's extensive experience spans NVOCC operations, trade financing, and designing supply chain solutions for major global companies. He excels in building one-stop service platforms and leveraging digital and automation software systems to provide efficient supply chain solutions.`,
      description: `${t("testimonial.Steven_description")}`,
      image: Stevan1,
      image1: Stevan2,
      linkedin: "https://www.linkedin.com/in/steven-xue-69539849",
    },
    // {
    //   name: "Diego",
    //   topTitle: "Europe",
    //   title: "Europe",
    //   description: [
    //     "Lorem ipsum dolor sit amet,",
    //     "consectetuer adipiscing elit sed diam",
    //     "nonummy nibh euismod tincidunt ut",
    //     "laoreet dolore magna aliquam erat",
    //     " volutpat.Ut wisi enim ad minim",
    //     "veniam, quis nostrud exerci tation.",
    //   ],
    //   image: diego1,
    //   image1: diego2,
    // },
    {
      name: "Moises Solis",
      topTitle: "Latin America",
      title: "Latin America",
      description: `${t("testimonial.Moises_description")}`,
      image: flores1,
      image1: flores2,
      linkedin: "https://www.linkedin.com/in/moises-solis-flores-99391186/",
    },
    {
      name: "M. Soliman",
      topTitle: "Middle East and Africa",
      title: "Middle East and Africa",
      description: `${t("testimonial.Soliman_description")}`,
      image: soliman2,
      image1: soliman1,
      linkedin: " https://www.linkedin.com/in/mas2030/",
    },
    {
      name: "Diego Putzolu",
      topTitle: "Europe",
      title: "Europe",
      description: `${t("testimonial.Diego_description")}`,
      image: diego1,
      image1: diego2,
      linkedin: "https://www.linkedin.com/in/diego-putzolu-97501b12b/",
    },
  ];

  const presidentStyle = {
    width: "200px",
    height: "200px",
  };

  const setActiveSlide = (index) => {
    setActiveIndex(index);
  };

  // function handleWheel(event) {
  //   event.preventDefault();
  //   sliderRef.current.scrollLeft += event.deltaY;
  // }
  const startDragging = (e) => {
    setIsMouseDown(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    setScrollLeft(sliderRef.current.scrollLeft);
  };

  const stopDragging = (event) => {
    setIsMouseDown(false);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isMouseDown) {
      return;
    }
    const x = e.pageX - sliderRef.current.offsetLeft;
    const scroll = x - startX;
    sliderRef.current.scrollLeft = scrollLeft - scroll;
  };

  const activeSlide = slides[activeIndex];

  return (
    <div
      className="container-fluid d-flex flex-column"
      style={{ backgroundColor: "rgb(12, 52, 89)", minHeight: "100vh" }}
    >
      <div className="container">
        <h1
          className="mt-5 mb-3 text-left"
          style={{
            color: "rgb(112, 214, 255)",
            fontWeight: "700",
            fontSize: "2rem",
          }}
        >
          {t("testimonial.BoardOfGovernors")}
        </h1>
        <p style={{ color: "rgb(161, 174, 183)", fontWeight: "500", fontSize: "1.188rem" }} className="text-left">
          {t("testimonial.BoardOfGovernorsPara")}
        </p>
      </div>

      {/* <div className="row justify-content-center">
        {slides.map((slide, index) => (
          <div className="col-auto m-2" key={index}>
            <img
              src={slide.image}
              className={`slider-image ${
                index === activeIndex ? "active" : ""
              }`}
              alt=""
              style={{
                borderRadius: "50%",
                width: "130px",
                height: "130px",
                objectFit: "cover",
                cursor: "pointer",
              }}
              onClick={() => setActiveSlide(index)}
            />
          </div>
        ))}
      </div> */}
      <div className="main-desktop">
        <div>
          <div
            className="main"
            // onWheel={handleWheel}
            ref={sliderRef}
            onMouseDown={startDragging}
            onMouseUp={stopDragging}
            onMouseLeave={stopDragging}
            onMouseMove={handleMouseMove}
          >
            {slides.map((slide, index) => {
              return (
                <div className="main-card-container" key={index}>
                  <h4
                    className="topTitle"
                    style={
                      {
                        fontSize: "1.28rem",
                        // fontWeight: "bold"
                        // paddingBottom: slide?.topTitle !== "President" && "30px",
                      }
                    }
                  >
                    {slide?.topTitle}
                  </h4>
                  <div className="main-card">
                    <div
                      key={index}
                      class="profile-card"
                      style={{
                        width: slide?.isPresident ? "200px" : "170px",
                        height: slide?.isPresident ? "200px" : "170px",
                      }}
                      onClick={() => setActiveSlide(index)}
                    >
                      <div class="img">
                        <img src={slide.image} alt="Profile Image" />
                      </div>
                      <div
                        class="caption"
                        style={{
                          transform: slide?.isPresident
                            ? "translateY(-60px)"
                            : "translateY(-80px);",
                        }}
                      >
                        <h3>{slide.name}</h3>
                        {/* <p>{slide.title}</p> 
                       <div class="social-links">
                          <a href={slides.linkedin}>
                             <i class="bi bi-linkedin"></i>
                           </a>
                         </div> */}
                      </div>
                    </div>
                    {/* {slide?.isPresident || slide?.isChairman ? (
                    <div className="divider-line"></div>
                  ) : (
                    ""
                  )} */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <div className="row justify-content-center align-items-center ">
            <div className="d-flex flex-column gap-5 gap-lg-0 flex-lg-row justify-content-between align-items-center mb-5 testimonial-area">
              <div className="testimonial-img">
                <img
                  src={activeSlide.image1}
                  alt=""
                  className="img-fluid "
                  style={{
                    width: "100%",
                    borderRadius: "5px",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="text-center testimonial-content">
                <h3 style={{ color: "rgb(98, 211, 255)", fontWeight: "700", fontSize: "2rem" }}>
                  {activeSlide.name}
                </h3>
                <p style={{ color: "rgb(161, 173, 182)", fontWeight: "bold", fontSize: "1.28rem" }}>
                  {activeSlide.title}
                </p>
                <div
                  className="bio-content"
                  style={{
                    color: "rgb(115, 170, 183)",
                    fontSize: "1.188rem",
                    fontWeight: "500",
                    textAlign: "center",
                    // width: "100%",
                  }}
                >
                  {/* {activeSlide.description.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))} */}
                  {activeSlide.description}
                </div>

                <div className="mt-5">
                  {/* Add your social media icon links here */}
                  <a
                    href={activeSlide.linkedin}
                    target="_blank"
                    className="m-2"
                  >
                    <i
                      className="bi bi-linkedin"
                      style={{ color: "white" }}
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main-mobile">
        <BoardOfGovernorsMobile slides={slides} />
      </div>
    </div>
  );
};

export default CustomSlider;
